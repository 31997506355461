.gradient {
  background: linear-gradient(
    -45deg,
    #005f73,
    #0a9396,
    #ee9b00,
    #ca6702,
    #bb3e03,
    #ae2012
  );
  background-size: 1000% 1000%;
  animation: gradient 20s ease infinite;
}

.jump {
  animation: jump 0.5s ease;
}

@keyframes jump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
