@import url("https://fonts.googleapis.com/css?family=Bebas+Neue");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Bebas Neue", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

:focus {
  outline: none;
}

.leaflet-tooltip {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 300;
  color: white;
  opacity: 1;
  padding: 16px;
  /* box-shadow: none; */
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid white;
}

a {
  color: #bb3e03;
  font-weight: 700;
  text-decoration: none;
}
